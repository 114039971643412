/* eslint-disable @next/next/no-img-element */
import React from "react";
import dynamic from "next/dynamic";
import Tooltip from "@/components/common/tooltip";
import { m, useAnimate } from "framer-motion";
import { Switch } from "@headlessui/react";
import CloseButton from "@/components/common/close-button";

const MdDialog = dynamic(() => import("./md-dialog"), { ssr: false });

const ConsentBanner = ({
  consent,
  setConsent,
  consentDialogOpen,
  setConsentDialogOpen,
  consentDate,
  setConsentDate,
  requireConsentInteraction,
  consentValid,
}) => {
  const [policyDialogLoad, setPolicyDialogLoad] = React.useState(false);
  const [policyDialogOpen, setPolicyDialogOpen] = React.useState(true);
  const [customizeConsent, setCustomizeConsent] = React.useState(false);
  const [customConsent, setCustomConsent] = React.useState(
    consentDate
      ? consent
      : {
          analytics_storage: "granted",
          ad_storage: "granted",
        },
  );

  const [mainDiv, animateMainDiv] = useAnimate();
  const [customizerDiv, animateCustomizerDiv] = useAnimate();
  const animation = {
    main: {
      show: {
        opacity: 1,
        y: 0,
        display: "flex",
      },
      hide: {
        opacity: 0,
        y: 100,
        transitionEnd: {
          display: "none",
        },
      },
    },
    customizer: {
      show: {
        display: "flex",
        opacity: 1,
        height: "auto", //customizerDivHeight,
      },
      hide: {
        opacity: 0,
        height: 0,
        transitionEnd: {
          display: "none",
        },
      },
    },
  };

  React.useEffect(() => {
    if (consentDialogOpen) {
      animateMainDiv(mainDiv.current, animation.main.show, {
        type: "spring",
      });
    } else {
      animateMainDiv(mainDiv.current, animation.main.hide, {
        type: "spring",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentDialogOpen]);

  React.useEffect(() => {
    if (customizeConsent) {
      animateCustomizerDiv(customizerDiv.current, animation.customizer.show, {
        duration: 0.3,
      });
    } else {
      animateCustomizerDiv(customizerDiv.current, animation.customizer.hide, {
        duration: 0.3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeConsent]);

  return (
    <m.div
      ref={mainDiv}
      className={`card fixed bottom-0 left-0 right-0 z-[1000] m-auto box-border max-h-[90vh] min-h-[200px] w-full max-w-[600px] flex-col items-center justify-center gap-2 overflow-visible p-2 text-auto-black/80 opacity-0 shadow-lg max-sm:overflow-y-scroll max-sm:rounded-b-none sm:bottom-6 sm:gap-4 sm:p-4`}
      initial={animation.main.hide}
      // animate="show"
      // transition={{ duration: 2 }}
    >
      {((consentDate && consentValid) ||
        (!consentDate && !requireConsentInteraction)) && (
        <CloseButton
          onClick={() => {
            setConsentDialogOpen(false);
            setCustomizeConsent(false);
          }}
        />
      )}
      <div className="flex w-full flex-row gap-2 text-center sm:gap-4">
        <div className="h-full w-auto text-center">
          <CookieImage className="h-16 w-16 sm:h-32 sm:w-32" />
        </div>
        <div className="align-center flex h-full w-auto flex-col justify-items-center gap-2 sm:gap-4">
          <div className="text-start text-3xl font-bold uppercase leading-none">
            We use cookies
          </div>
          <div
            className={`flex flex-row items-center justify-between ${
              customizeConsent ? "max-sm:max-h-0" : ""
            } max-h-96 overflow-hidden transition-[max-height] duration-300 ease-in-out`}
          >
            <div className="text-start">
              We use cookies and other tracking technologies to improve your
              browsing experience on our website, to show you personalized
              content and targeted ads, to analyze our website traffic, and to
              understand where our visitors are coming from.
            </div>
          </div>
        </div>
      </div>
      <m.div
        ref={customizerDiv}
        className="hidden h-0 w-full flex-col gap-2 opacity-0 sm:gap-4"
        initial={animation.customizer.hide}
      >
        <div className="card !overflow-visible rounded bg-auto-black/5 p-2">
          <div className="flex flex-row items-center justify-between">
            <div className="text-xl font-bold uppercase">
              Strictly Necessary
            </div>
            <Tooltip description="Always allowed" side="top">
              <Switch
                checked
                className="relative box-content inline-flex h-4 w-8 shrink-0 cursor-not-allowed items-center rounded-full border-2 border-transparent bg-auto-black/50 opacity-30 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75  ui-checked:bg-success"
              >
                <span className="sr-only">Always Enabled</span>
                <span
                  aria-hidden
                  className="h-4 w-4 transform rounded-full bg-white/80 shadow transition duration-200 ease-in-out ui-checked:translate-x-4"
                />
              </Switch>
            </Tooltip>
          </div>
          <div className="flex flex-row">
            <div className="text-xs">
              These cookies are crucial for delivering the services offered
              through our website and allowing you to utilize specific features.
              If these cookies are not enabled, certain services on our website
              may not be accessible.
            </div>
          </div>
        </div>
        <div className="card rounded bg-auto-black/5 p-2">
          <div className="flex flex-row items-center justify-between">
            <div className="text-xl font-bold uppercase">Analytics</div>
            <Switch
              checked={customConsent.analytics_storage === "granted"}
              onChange={() => {
                setCustomConsent({
                  ...customConsent,
                  analytics_storage:
                    customConsent.analytics_storage === "granted"
                      ? "denied"
                      : "granted",
                });
              }}
              className="relative box-content inline-flex h-4 w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-auto-black/50 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75  ui-checked:bg-success"
            >
              <span className="sr-only">Enable Analytics</span>
              <span
                aria-hidden
                className="h-4 w-4 transform rounded-full bg-white/80 shadow transition duration-200 ease-in-out ui-checked:translate-x-4"
              />
            </Switch>
          </div>
          <div className="flex flex-row">
            <div className="text-xs">
              {
                "These cookies are employed to gather data for analyzing website traffic and visitor interactions. For instance, they might monitor factors like your duration on the site or the pages you view, aiding us in enhancing the website experience. The data collected by these tracking and performance cookies is anonymous and doesn't identify individual visitors."
              }
            </div>
          </div>
        </div>
        <div className="card rounded bg-auto-black/5 p-2">
          <div className="flex flex-row items-center justify-between">
            <div className="text-xl font-bold uppercase">Advertising</div>
            <Switch
              checked={customConsent.ad_storage === "granted"}
              onChange={() => {
                setCustomConsent({
                  ...customConsent,
                  ad_storage:
                    customConsent.ad_storage === "granted"
                      ? "denied"
                      : "granted",
                });
              }}
              className="relative box-content inline-flex h-4 w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-auto-black/50 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75  ui-checked:bg-success"
            >
              <span className="sr-only">Enable Advertising</span>
              <span
                aria-hidden
                className="h-4 w-4 transform rounded-full bg-white/80 shadow transition duration-200 ease-in-out ui-checked:translate-x-4"
              />
            </Switch>
          </div>
          <div className="flex flex-row">
            <div className="text-xs">
              {
                "These cookies are utilized to display personalized ads tailored to your browsing preferences. These cookies, provided by our content and advertising partners, might blend data gathered from our site with information they've independently amassed from your web browser's interactions across their network of sites. If you opt to delete or deactivate these targeting or advertising cookies, you may still encounter ads, though they might not align with your interests."
              }
            </div>
          </div>
        </div>
      </m.div>
      <div className="grid w-full grid-flow-col gap-1 sm:gap-3">
        <button
          className="button-primary"
          onClick={() => {
            setConsent({
              analytics_storage: "granted",
              ad_storage: "granted",
            });
            setConsentDate(new Date());
            setConsentDialogOpen(false);
          }}
        >
          Accept All
        </button>
        <button
          className="button"
          onClick={() => {
            if (!customizeConsent) setCustomizeConsent(true);
            else if (customizeConsent) {
              setCustomizeConsent(false);
              setConsent(customConsent);
              setConsentDate(new Date());
              setConsentDialogOpen(false);
            }
          }}
        >
          {customizeConsent ? "Accept Selected" : "Customize"}
        </button>
        <button
          className="button"
          onClick={() => setPolicyDialogLoad(true) || setPolicyDialogOpen(true)}
        >
          Learn More
        </button>
      </div>
      {policyDialogLoad && (
        <MdDialog
          mdFileName={"privacy-policy.md"}
          openMdDialog={policyDialogOpen}
          setOpenMdDialog={setPolicyDialogOpen}
        />
      )}
    </m.div>
  );
};

export default ConsentBanner;

const CookieImage = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      style={{ fill: "#A58763" }}
      d="M493.233,183.785c6.898,22.851,10.671,47.102,10.671,72.215
	c0,136.885-111.017,247.902-247.902,247.902S8.099,392.885,8.099,256S119.116,8.098,256.001,8.098
	c13.688,0,27.054,1.186,40.096,3.234c-11.425,11.748-18.538,27.701-18.538,45.269c0,35.676,28.994,64.67,64.671,64.67
	c20.91,0,39.341-9.916,51.197-25.222c5.282,6.79,11.856,12.611,19.509,16.814c-0.431,2.802-0.647,5.605-0.647,8.408
	c0,35.676,28.994,64.67,64.67,64.67C482.563,185.941,487.952,185.186,493.233,183.785z"
    />
    <path
      style={{ fill: "#8D7454" }}
      d="M158.996,256c0-90.037,48.038-168.871,119.868-212.294c2.544-12.424,8.677-23.578,17.233-32.376
	c-13.042-2.047-26.406-3.233-40.096-3.233C119.116,8.098,8.099,119.114,8.099,256s111.017,247.902,247.902,247.902
	c26.312,0,51.653-4.137,75.449-11.736C231.452,460.232,158.996,366.573,158.996,256z"
    />
    <path
      d="M256.001,512c-141.159,0-256-114.841-256-256s114.841-256,256-256c13.362,0,27.275,1.121,41.351,3.331l15.458,2.427
	l-10.909,11.218C291.424,27.75,285.654,41.822,285.654,56.6c0,31.195,25.379,56.573,56.573,56.573
	c17.597,0,33.924-8.049,44.795-22.083l6.389-8.248l6.405,8.235c4.813,6.188,10.539,11.131,17.017,14.688l4.967,2.727l-0.862,5.601
	c-0.367,2.386-0.554,4.799-0.554,7.176c0,31.195,25.378,56.572,56.572,56.572c4.772,0,9.417-0.617,14.198-1.885l7.566-2.007
	l2.263,7.493c7.311,24.212,11.016,49.296,11.016,74.556C512.001,397.159,397.16,512,256.001,512z M256.001,16.195
	C123.773,16.195,16.197,123.771,16.197,256s107.576,239.805,239.805,239.805S495.806,388.228,495.806,256
	c0-21.207-2.788-42.282-8.294-62.756c-3.498,0.532-6.995,0.795-10.554,0.795c-40.124,0-72.767-32.643-72.767-72.767
	c0-1.377,0.046-2.762,0.138-4.148c-3.89-2.562-7.53-5.525-10.898-8.871c-13.596,13.521-31.793,21.117-51.201,21.117
	c-40.125,0-72.768-32.644-72.768-72.768c0-13.974,3.995-27.445,11.444-39.086C272.441,16.637,264.106,16.195,256.001,16.195z"
    />
    <g>
      <path
        style={{ fill: "#704324" }}
        d="M288.337,272.167c14.874,0,26.946,12.072,26.946,26.946c0,14.874-12.072,26.946-26.946,26.946
		c-14.874,0-26.946-12.072-26.946-26.946C261.391,284.239,273.462,272.167,288.337,272.167z"
      />
      <path
        style={{ fill: "#704324" }}
        d="M385.342,304.502c14.874,0,26.946,12.072,26.946,26.946c0,14.874-12.072,26.946-26.946,26.946
		s-26.946-12.072-26.946-26.946C358.396,316.574,370.467,304.502,385.342,304.502z"
      />
      <path
        style={{ fill: "#704324" }}
        d="M309.893,369.173c14.874,0,26.946,12.072,26.946,26.946s-12.072,26.946-26.946,26.946
		s-26.946-12.072-26.946-26.946S295.019,369.173,309.893,369.173z"
      />
      <path
        style={{ fill: "#704324" }}
        d="M175.163,121.27c20.802,0,37.725,16.922,37.725,37.725s-16.922,37.725-37.725,37.725
		s-37.725-16.922-37.725-37.725S154.362,121.27,175.163,121.27z"
      />
      <path
        style={{ fill: "#704324" }}
        d="M115.883,218.275c14.874,0,26.946,12.072,26.946,26.946s-12.072,26.946-26.946,26.946
		c-14.874,0-26.946-12.072-26.946-26.946S101.009,218.275,115.883,218.275z"
      />
    </g>
    <path
      d="M175.163,204.816c-25.266,0-45.821-20.555-45.821-45.821s20.555-45.821,45.821-45.821s45.821,20.555,45.821,45.821
	S200.43,204.816,175.163,204.816z M175.163,129.368c-16.336,0-29.626,13.291-29.626,29.626s13.291,29.626,29.626,29.626
	s29.626-13.291,29.626-29.626S191.499,129.368,175.163,129.368z"
    />
    <path
      d="M115.883,280.265c-19.322,0-35.044-15.721-35.044-35.044s15.721-35.044,35.044-35.044s35.044,15.721,35.044,35.044
	S135.206,280.265,115.883,280.265z M115.883,226.373c-10.393,0-18.848,8.455-18.848,18.848c0,10.393,8.455,18.848,18.848,18.848
	s18.848-8.455,18.848-18.848C134.731,234.828,126.276,226.373,115.883,226.373z"
    />
    <path
      d="M385.341,366.492c-19.322,0-35.044-15.721-35.044-35.044c0-19.322,15.721-35.044,35.044-35.044
	c19.322,0,35.044,15.721,35.044,35.044C420.385,350.77,404.664,366.492,385.341,366.492z M385.341,312.6
	c-10.393,0-18.848,8.455-18.848,18.848c0,10.393,8.455,18.848,18.848,18.848s18.848-8.455,18.848-18.848
	C404.189,321.055,395.734,312.6,385.341,312.6z"
    />
    <path
      d="M309.894,431.162c-19.322,0-35.044-15.721-35.044-35.044s15.721-35.044,35.044-35.044c19.322,0,35.044,15.721,35.044,35.044
	S329.216,431.162,309.894,431.162z M309.894,377.27c-10.393,0-18.848,8.455-18.848,18.848s8.455,18.848,18.848,18.848
	c10.393,0,18.848-8.455,18.848-18.848S320.287,377.27,309.894,377.27z"
    />
    <path
      d="M288.337,334.157c-19.322,0-35.044-15.721-35.044-35.044c0-19.322,15.721-35.044,35.044-35.044
	c19.322,0,35.044,15.721,35.044,35.044C323.381,318.436,307.659,334.157,288.337,334.157z M288.337,280.265
	c-10.393,0-18.848,8.455-18.848,18.848s8.455,18.848,18.848,18.848c10.393,0,18.848-8.455,18.848-18.848
	S298.73,280.265,288.337,280.265z"
    />
    <g>
      <rect
        x="304.507"
        y="177.847"
        style={{ fill: "#FFFFFF" }}
        width="21.557"
        height="16.195"
      />
      <rect
        x="336.844"
        y="210.173"
        style={{ fill: "#FFFFFF" }}
        width="21.556"
        height="16.195"
      />
      <rect
        x="347.62"
        y="167.061"
        style={{ fill: "#FFFFFF" }}
        width="21.557"
        height="16.195"
      />
      <rect
        x="99.711"
        y="371.858"
        style={{ fill: "#FFFFFF" }}
        width="21.557"
        height="16.195"
      />
      <rect
        x="121.272"
        y="339.521"
        style={{ fill: "#FFFFFF" }}
        width="21.557"
        height="16.195"
      />
      <rect
        x="142.823"
        y="371.858"
        style={{ fill: "#FFFFFF" }}
        width="21.557"
        height="16.195"
      />
    </g>
  </svg>
);
