import { XMarkIcon } from "@heroicons/react/20/solid";

const CloseButton = ({ onClick }) => {
  return (
    <button
      className="focus:ouline-none absolute right-2 top-3 text-auto-black/80 max-sm:hidden"
      aria-label="close"
      onClick={onClick}
    >
      <XMarkIcon className="h-8 w-8" />
    </button>
  );
};

export default CloseButton;
